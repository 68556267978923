
import { defineComponent } from 'vue';
import { DmIcon } from '@dobrymechanik/vue-ui';
import { AppWrapper } from '@/components';

export default defineComponent({
  name: 'NotFound',
  components: {
    DmIcon,
    AppWrapper
  }
});
