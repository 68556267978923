import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmIcon = _resolveComponent("DmIcon")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmIcon, {
        name: "ghost",
        type: "fa",
        prefix: "fa-",
        class: _ctx.$bem({e: 'icon'}),
        size: "10x"
      }, null, 8, ["class"]),
      _createVNode("h2", {
        class: _ctx.$bem({e: 'text'})
      }, _toDisplayString(_ctx.$t('app.pageNotFound')), 3)
    ]),
    _: 1
  }, 8, ["class"]))
}